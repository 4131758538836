import React from 'react';
import Image from '../Image/Image';

import HeatherSunset from '../../img/1.jpg';

import style from './Page.module.css';
import Navigation from '../Navigation/Navigation';
import { Col, Container, Row } from 'react-bootstrap';
import Gallery from '../Gallery/Gallery';

const Page = () => {


    return (
        <Container fluid>
            <Row>
                <Col className={style.noPaddingCol}>
                    <div className={style.header}>
                        <h1 className={style.whiteText}>My 2021 in pictures</h1>
                        <p className={style.whiteText}>A selection of my favourite photos taken in 2021</p>
                        <p className={style.whiteText}>(TODO: Click on a photo to view it in full screen)</p>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col className={style.noPaddingCol}>
                    <Gallery />
                </Col>
            </Row>
        </Container>
    );
}

export default Page;