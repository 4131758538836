import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import style from './Gallery.module.css';
import ModalContainer from '../ModalContainer/ModalContainer';

const Gallery = () => {

    const [modalOpen, setModalOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState('');

    const photos = [
        {
            id: 1,
            imageURL: "https://staging.markvanspall.com/img/1.jpg",
            caption: "Backlit heather on top of Bosley Cloud"
        },
        {
            id: 2,
            imageURL: "https://staging.markvanspall.com/img/1a.jpg",
            caption: "A macro shot of a water drop about 2mm wide"
        },
        {
            id: 3,
            imageURL: "https://staging.markvanspall.com/img/2speed.jpg",
            caption: "Light trails on a dark, drizzly evening"
        },
        {
            id: 4,
            imageURL: "https://staging.markvanspall.com/img/1118.jpg",
            caption: "One for a series of shots mapping June's eclipse for a friend's data project"
        },
        {
            id: 5,
            imageURL: "https://staging.markvanspall.com/img/angles.jpg",
            caption: "Jackpot! I'd been waiting over a year to spot a farmer working a field while I had my drone with me"
        },
        {
            id: 6,
            imageURL: "https://staging.markvanspall.com/img/congleton-bypass-tilt-shift.jpg",
            caption: "Tilt shift photography of the new bypass in Congleton"
        },
        {
            id: 7,
            imageURL: "https://staging.markvanspall.com/img/DJI_0604.jpg",
            caption: "Looking towards Jodrell Bank from Holmes Chapel"
        },
        {
            id: 8,
            imageURL: "https://staging.markvanspall.com/img/framing.jpg",
            caption: "Experimenting with framing"
        },
        {
            id: 9,
            imageURL: "https://staging.markvanspall.com/img/IMG_0733.jpg",
            caption: "My little dude"
        },
        {
            id: 10,
            imageURL: "https://staging.markvanspall.com/img/IMG_4055.jpg",
            caption: "Experimenting with different post processing techniques"
        },
        {
            id: 11,
            imageURL: "https://staging.markvanspall.com/img/IMG_4109.jpg",
            caption: "Cherry blossom. My first 'proper' shot years ago was of cherry blossom, so it's an annual must"
        },
        {
            id: 12,
            imageURL: "https://staging.markvanspall.com/img/IMG_4418.jpg",
            caption: "The long and winding road"
        },
        {
            id: 13,
            imageURL: "https://staging.markvanspall.com/img/IMG_4790.jpg",
            caption: "Ian Curtis mural in Manchester"
        },
        {
            id: 14,
            imageURL: "https://staging.markvanspall.com/img/IMG_4806.jpg",
            caption: "Manchester street photography"
        },
        {
            id: 15,
            imageURL: "https://staging.markvanspall.com/img/IMG_4834-2.jpg",
            caption: "St Peter's Square"
        },
        {
            id: 16,
            imageURL: "https://staging.markvanspall.com/img/IMG_5040.jpg",
            caption: "Whitby"
        },
        {
            id: 17,
            imageURL: "https://staging.markvanspall.com/img/IMG_5318.jpg",
            caption: "The pier at Lytham St Annes"
        },
        {
            id: 18,
            imageURL: "https://staging.markvanspall.com/img/IMG_5208.jpg",
            caption: "Seaside sunset"
        },
        {
            id: 19,
            imageURL: "https://staging.markvanspall.com/img/IMG_5314.jpg",
            caption: "Sweet treats"
        },
        {
            id: 20,
            imageURL: "https://staging.markvanspall.com/img/IMG_5336.jpg",
            caption: "Golden sands - Teaching my daughter about bokeh"
        },
        {
            id: 21,
            imageURL: "https://staging.markvanspall.com/img/IMG_5126.jpg",
            caption: "Whitby streetlight"
        },
        {
            id: 22,
            imageURL: "https://staging.markvanspall.com/img/pat.jpg",
            caption: "Postman Pat - More tilt shift fun"
        },
        {
            id: 23,
            imageURL: "https://staging.markvanspall.com/img/space.jpg",
            caption: "Dusting off my telephoto lens to capture the Moon"
        },
        {
            id: 24,
            imageURL: "https://staging.markvanspall.com/img/texture.jpg",
            caption: "I bought a ~100 year old glass plate camera. Even the case has history."
        },
        {
            id: 25,
            imageURL: "https://staging.markvanspall.com/img/PXL_20210527_135559438.MP.jpg",
            caption: "Macro photography"
        }
    ];

    const openModal = () => {
        console.log('test');
        setModalOpen(true);
    }

    const handleClose = () => {
        setModalOpen(false);
    }


    return (
        <Container fluid>
            <Row>
                {photos.map((photo, index) => (
                    <Col xs={12} sm={12} md={6} lg={3}>
                        <div className={style.colInner} onClick={openModal}>
                            <div className={style.thumbnailContainer}>
                                <div className={style.imageContainer}>
                                    <img key={index} src={photo.imageURL} className={style.imageThumbnail} />
                                </div>
                                <div className={style.captionContainer}>
                                    <p className={style.text}>{photo.caption}</p>
                                </div>
                            </div>
                        </div>
                    </Col>
                ))}
            </Row>
            <ModalContainer photo={selectedImage} handleClose={handleClose} />
        </Container>
    );
}

export default Gallery;