import React from 'react';
import { Modal } from 'react-bootstrap';
import style from './ModalContainer.module.css';

const ModalContainer = (props) => {


    return (
        <Modal show={props.modalOpen} onHide={props.handleClose}>
            <Modal.Body>
                <img src={props.photo.imageURL} className={style.fullSizeImage} />
            </Modal.Body>
        </Modal>
    );
}

export default ModalContainer;