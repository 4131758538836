import './css/bootstrap.min.css';
import './App.css';
import Page from './components/Page/Page';
import { Container } from 'react-bootstrap';

function App() {
  return (
    <Container fluid className="App">
      <Page />
    </Container>
  );
}

export default App;
